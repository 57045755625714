<template>
    <div>
        <template>
            <h1>Trânsito de pacotes</h1>
            <v-divider class="mb-4" />
        </template>

        <v-alert border="bottom" colored-border icon="search" :color="items && items.length > 0 ? 'grey' : 'error'" outlined>
            <v-layout wrap>
                <v-flex xs12 md4>
                    <UserSelect :value="selectedUser" endpoint="checklists/collect/users" label="Especialista" @change="selectedUser = $event" />
                </v-flex>
                <v-flex xs12 md4>
                    <v-autocomplete v-model="selectedStore" :items="stores" item-text="name" item-value="name" label="Loja" return-object :disabled="!loaded" :loading="!loaded"
                        clearable />
                </v-flex>
                <v-flex xs12 md3>
                    <DateInterval today :label="$t('Data')" @onChange="dates = $event" />
                </v-flex>
                <v-flex xs12 md1 mt-4 text-center>
                    <v-btn color="success" @click="getItems" small>Buscar</v-btn>
                </v-flex>
                <v-flex xs12>
                    <v-autocomplete v-model="filterSelecteds" :items="filterOptions" item-text="name" item-value="code" :label="$t('Tipo de filtro')" multiple />
                </v-flex>
            </v-layout>
        </v-alert>

        <DownloadCsv :data="csv" name="relatorio.csv" :labels="csvLabels" delimiter=";">
            <v-btn color="success" width="100%" small>Exportar resultado (csv)</v-btn>
        </DownloadCsv>

        <div>
            <v-divider class="mb-4" />
            <v-layout wrap class="ma-12">
                <v-flex xs12 sm6>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                {{ total }}
                            </div>
                            Total de pacotes
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm6>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>hourglass_empty</v-icon>
                                {{ waiting }}
                            </div>
                            Aguardando
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm6>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>local_shipping</v-icon>
                                {{ collected }}
                            </div>
                            Pacotes Coletados
                        </v-card-text>
                        <v-card-subtitle v-if="errorNotificationCollected > 0" class="red--text">
                            <strong>{{ errorNotificationCollected }} erro de notificação para NEC</strong>
                        </v-card-subtitle>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm6>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>warehouse</v-icon>
                                {{ received }}
                            </div>
                            Pacotes Recebidos
                        </v-card-text>
                        <v-card-subtitle v-if="errorNotificationReceived > 0" class="red--text">
                            <strong>{{ errorNotificationReceived }} erro de notificação para NEC</strong>
                        </v-card-subtitle>
                    </v-card>
                </v-flex>
                <v-flex xs12 v-if="devolution > 0">

                    <v-card color="error">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>assignment_return</v-icon>
                                {{ devolution }}
                            </div>
                            <div class="d-flex flex-no-wrap justify-space-between">
                                Devolvidos
                                <DownloadCsv :data="csvDevolution" name="devoluções.csv" :labels="csvLabelsDevolution" delimiter=";">
                                    <v-btn x-small color="success">Exportar</v-btn>
                                </DownloadCsv>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
            <v-divider class="mb-4" />
        </div>

        <SimpleTable formclass="Package/form" endpoint="packages" :items="items" :aditionalColumns="headers" hideNew hideEdit hideDelete showExpand>

            <template v-slot:extra-column="item">
                <v-chip class="ma-0" outlined color="success" v-if="item.collectedBy">
                    Coletado
                </v-chip>
                <v-chip class="ma-0" outlined color="error" v-else>
                    Ainda não coletado
                </v-chip>
            </template>
            <template v-slot:more-extra-column="item">
                <v-chip class="ma-0" outlined color="success" v-if="item.receivedBy">
                    Recebido
                </v-chip>
                <v-chip class="ma-0" outlined color="error" v-else>
                    Ainda não recebido
                </v-chip>
            </template>

            <template v-slot:expanded="{ headers, item }">
                <td :colspan="headers.length">
                    <v-layout wrap class="ma-12">
                        <v-card color="primary" class="mx-auto" max-width="400">
                            <v-card-text class="white--text">
                                <v-flex xs12>
                                    <b>Criado na fase:</b> {{ item.createMode }}
                                </v-flex>
                                <v-flex xs12 v-if="item.createdBy">
                                    <b>Criado por:</b> {{ item.createdBy }}
                                </v-flex>
                                <v-flex xs12 v-if="item.createdAt">
                                    <b>Criado:</b> {{ $moment(item.createdAt).format("DD/MM/YY HH:mm") }}
                                </v-flex>
                                <v-flex xs12 v-if="item.updatedAt">
                                    <b>Última atualização:</b> {{ $moment(item.updatedAt).format("DD/MM/YY HH:mm") }}
                                </v-flex>
                                <v-flex xs12 v-if="item.necCreatedAt">
                                    <b>Criado(NEC):</b> {{ $moment(item.necCreatedAt).format("DD/MM/YY HH:mm") }}
                                </v-flex>
                                <v-flex xs12 v-if="item.necUpdatedAt">
                                    <b>Última atualização(NEC):</b> {{ $moment(item.necUpdatedAt).format("DD/MM/YY HH:mm") }}
                                </v-flex>
                            </v-card-text>
                        </v-card>
                        <v-card color="primary" class="mx-auto" max-width="400" v-if="item.collectedBy">
                            <v-card-text class="white--text">
                                <v-flex xs12 class="text-center">
                                    <b>Coletado</b>
                                </v-flex>
                                <v-flex xs12>
                                    <b>Data:</b> {{ $moment(item.collectedDate).format("DD/MM/YY HH:mm") }}
                                </v-flex>
                                <v-flex xs12>
                                    <b>Coletado por:</b> {{ item.collectedBy }}
                                </v-flex>
                                <v-flex xs12>
                                    <b>Romaneio:</b> {{ item.checklistCollect }}
                                </v-flex>
                            </v-card-text>
                        </v-card>
                        <v-card color="primary" class="mx-auto" max-width="400" v-if="item.receivedBy">
                            <v-card-text class="white--text">
                                <v-flex xs12 class="text-center">
                                    <b>Recebido</b>
                                </v-flex>
                                <v-flex xs12>
                                    <b>Data:</b> {{ $moment(item.receivedDate).format("DD/MM/YY HH:mm") }}
                                </v-flex>
                                <v-flex xs12>
                                    <b>Recebido por:</b> {{ item.receivedBy }}
                                </v-flex>
                                <v-flex xs12>
                                    <b>Romaneio:</b> {{ item.checklistReceive }}
                                </v-flex>
                                <v-flex xs12 v-if="item.driverCollectPack">
                                    <b>Motorista:</b> {{ item.driverCollectPack }}
                                </v-flex>
                                <v-flex xs12 v-if="item.driverCollectPackExternal">
                                    <b>Motorista tercerizado:</b> {{ item.driverCollectPackExternal }}
                                </v-flex>
                            </v-card-text>
                        </v-card>
                        <v-card color="primary" class="mx-auto" max-width="500" v-if="item.key">
                            <v-card-text class="white--text">
                                <v-flex xs12 class="text-center">
                                    <b>Integração NEC</b>
                                </v-flex>
                                <v-flex xs12>
                                    <b>Integração:</b> {{ item.integrationControl }}
                                </v-flex>
                                <v-flex xs12>
                                    <b>Chave:</b> {{ item.key }}
                                </v-flex>
                                <v-flex xs12>
                                    <b>Enviado (coleta):</b> {{ item.sendCollected ? "Sim" : "Não" }}
                                </v-flex>
                                <v-flex xs12>
                                    <b>Enviado (recebimento):</b> {{ item.sendReceived ? "Sim" : "Não" }}
                                </v-flex>
                            </v-card-text>
                        </v-card>
                        <v-card color="primary" class="mx-auto mt-2" max-width="400" v-for="(returnItem, index) in item.returnList" :key="index">
                            <v-card-text class="white--text">
                                <v-flex xs12 class="text-center">
                                    <b>Devolução</b>
                                </v-flex>
                                <v-flex xs12>
                                    <b>Data:</b> {{ $moment(returnItem.date).format("DD/MM/YY HH:mm") }}
                                </v-flex>
                                <v-flex xs12>
                                    <b>Recebido por:</b> {{ returnItem.createdBy }}
                                </v-flex>
                                <v-flex xs12>
                                    <b>Romaneio:</b> {{ returnItem.checklist }}
                                </v-flex>
                                <v-flex xs12 v-if="returnItem.driver">
                                    <b>Motorista:</b> {{ returnItem.driver }}
                                </v-flex>
                                <v-flex xs12 v-if="returnItem.driverExternal">
                                    <b>Motorista tercerizado:</b> {{ returnItem.driverExternal }}
                                </v-flex>
                                <v-flex xs12>
                                    <b>Enviado (devolução):</b> {{ item.sendDevolution ? "Sim" : "Não" }}
                                </v-flex>
                            </v-card-text>
                        </v-card>
                    </v-layout>
                </td>
            </template>

        </SimpleTable>
    </div>
</template>

<script>
import DownloadCsv from "vue-json-csv";
import UserSelect from "@/components/shared/UserSelect";
import DateInterval from "@/components/core/DateInterval";
import SimpleTable from "@/components/core/SimpleTable";

export default {
    components: {
        DateInterval,
        SimpleTable,
        DownloadCsv,
        UserSelect
    },

    data() {
        return {
            timer: null,
            loaded: false,
            dates: [],
            items: [],
            stores: [],
            selectedStore: null,
            selectedUser: null,
            headers: [
                { text: this.$t("Recebido"), value: "more-extra-column", align: "center", sortable: true },
                { text: this.$t("Coletado"), value: "extra-column", align: "center", sortable: true },
                { text: this.$t("Loja"), value: "store", align: "right", sortable: false },
                { text: this.$t("Pacote"), value: "order", align: "right", sortable: false },
            ],
            csvLabels: {
                createdAt: "Data importação",
                store: "Loja",
                order: "Pedido",
                key: "Chave CTE",
                volumeCode: "Código Volume",
                collectedDate: "Data coleta",
                collectedBy: "Coletado por",
                checklistCollect: "Romaneio de coleta",
                receivedDate: "Data recebimento",
                receivedBy: "Recebido por",
                checklistReceive: "Romaneio de recebimento",
                sendCollected: "Status NEC coleta",
                sendReceived: "Status NEC recebimento",
                devolution: "Número de devoluções",
                clientName: "Nome Cliente",
                clientAddress: "Endereço Cliente",
            },
            csvLabelsDevolution: {
                store: "Loja",
                order: "Pedido",
                key: "Chave CTE",
                volumeCode: "Código Volume",
                driver: "Motorista",
                collectedDate: "Data da coleta",
                devolutionAt: "Data da devolução",
                details: "Detalhes",
            },
            filterOptions: [
                { "code": "collected", "name": "Coletados" },
                { "code": "nonCollected", "name": "Não Coletados" },
                { "code": "received", "name": "Recebidos" },
                { "code": "nonReceived", "name": "Não Recebido" },
                { "code": "returned", "name": "Com devolução" },
                { "code": "nonReturned", "name": "Sem devolução" },
                { "code": "nonCollectedYesterday", "name": "Pacotes não coletados do dia anterior" },
            ],
            filterSelecteds: []
        };
    },

    created() {
        this.getStores();
    },

    mounted() {
        this.getItems();

        this.timer = setInterval(this.search, 30000);
    },

    computed: {
        csv() {
            if (this.items.length > 0) {
                return this.items.map((i) => {
                    return {
                        createdAt: i.createdAt ? this.$moment(i.createdAt).format("DD/MM/YYYY HH:mm") : null,
                        store: i.store,
                        order: i.order,
                        key: i.key,
                        volumeCode: i.volumeCode,
                        collectedDate: i.collectedDate ? this.$moment(i.collectedDate).format("DD/MM/YYYY HH:mm") : null,
                        collectedBy: i.collectedBy,
                        checklistCollect: i.checklistCollect,
                        receivedDate: i.receivedDate ? this.$moment(i.receivedDate).format("DD/MM/YYYY HH:mm") : null,
                        receivedBy: i.receivedBy,
                        checklistReceive: i.checklistReceive,
                        sendCollected: i.sendCollected == true ? "Enviado" : (i.sendCollected == false ? "Erro" : null),
                        sendReceived: i.sendReceived == true ? "Enviado" : (i.sendReceived == false ? "Erro" : null),
                        devolution: i.returnList && i.returnList.length > 0 ? i.returnList.length : null,
                        clientName: i.client && i.client.name ? i.client.name : null,
                        clientAddress: i.client && i.client.address ? i.client.address : null,
                    };
                });
            }

            return [];
        },
        csvDevolution() {
            if (this.items.length > 0) {
                let list = [];
                this.items.filter(i => i.returnList && i.returnList.length > 0).forEach((_package) => {
                    let returnPack = false;
                    if (_package.collectedDate && this.$moment.duration(this.$moment().utc().diff(this.$moment(_package.collectedDate))).asDays() >= 4) {
                        returnPack = true;
                    }

                    _package.returnList.forEach((devolution) => {
                        list.push({
                            store: _package.store,
                            order: _package.order,
                            key: _package.key,
                            volumeCode: _package.volumeCode,
                            collectedDate: _package.collectedDate ? this.$moment(_package.collectedDate).format("DD/MM/YYYY HH:mm") : null,
                            devolutionAt: devolution.date ? this.$moment(devolution.date).format("DD/MM/YYYY HH:mm") : null,
                            driver: devolution.driver,
                            details: returnPack == true ? `O mesmo já foi coletado a mais de 4 dias e deve ser devolvido e não mais ser feito tentativa de entrega.` : null,
                        });
                    })
                });
                return list;
            }

            return [];
        },
        total() { return Number.parseFloat(this.items.length).toFixed(0) },
        collected() { return Number.parseFloat(this.items.filter(i => i.collectedDate).length).toFixed(0) },
        received() { return Number.parseFloat(this.items.filter(i => i.receivedDate).length).toFixed(0) },
        devolution() { return Number.parseFloat(this.items.filter(i => i.returnList && i.returnList.length > 0).length).toFixed(0) },
        waiting() { return Number.parseFloat(this.items.filter(i => i.collectedDate == undefined && i.receivedDate == undefined).length).toFixed(0) },
        errorNotificationCollected() { return Number.parseFloat(this.items.filter(i => i.collectedDate && i.sendCollected != true).length).toFixed(0) },
        errorNotificationReceived() { return Number.parseFloat(this.items.filter(i => i.receivedDate && i.sendReceived != true).length).toFixed(0) },
    },

    methods: {
        getStores() {
            this.$http
                .get(`checklists/report/stores`)
                .then((result) => {
                    if (result) {
                        this.stores = result;
                        this.loaded = true
                    }
                })
                .catch(() => {
                    this.stores = [];
                });
        },
        search() {
            this.getItems();
        },
        getItems() {
            let query = this.dates.length == 2 ? `?from=${this.dates[0]}&to=${this.dates[1]}` : `?from=${this.dates[0]}&to=${this.dates[0]}`;

            if (this.selectedStore) {
                query += `&store=${this.selectedStore.id}`;
            }
            if (this.selectedUser) {
                query += `&collectedBy=${this.selectedUser}`;
            }
            if (this.filterSelecteds) {
                query += `&moreFilter=${encodeURIComponent(JSON.stringify(this.filterSelecteds))}`;
            }

            this.$http
                .get(`packages${query}&timezone=-3`)
                .then((result) => (this.items = result))
                .catch(() => (this.items = []));
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>